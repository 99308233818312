import * as React from 'react'

import { DefaultLayout } from 'layouts'
import { Seo } from 'components/helpers'
import NotFoundView from 'views/not-found'

const NotFoundPage: React.FC = () => (
  <DefaultLayout>
    <Seo title="Página não encontrada" />
    <NotFoundView />
  </DefaultLayout>
)

export default NotFoundPage
