import React from 'react'
import { Block, Title, Text, Vector, Button } from '@superlogica/design-ui'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Link } from 'components/helpers'

import * as S from './View.styled'

const NotFoundView = () => (
  <Block
    tag="section"
    bgColor="babar25"
    display="flex"
    alignItems="center"
    paddingTop={100}
    paddingBottom={{ xxxs: 40, md: 100 }}
    minHeight={{ xxxs: 'calc(100vh - 120px)', sm: 'calc(100vh - 60px)' }}
  >
    <Container>
      <Row justify="center">
        <Col xs={12} md={10}>
          <S.Card>
            <Vector
              name="search-remove"
              width={{ xxxs: 40, sm: 60 }}
              height={{ xxxs: 40, sm: 60 }}
              color="sonic500"
              marginTop={{ xxxs: 0, sm: 8 }}
              marginBottom={{ xxxs: 24, sm: 0 }}
              marginRight={{ xxxs: 0, sm: 48 }}
            />
            <Block tag="header">
              <Title
                size="display03"
                font="heading"
                color="babar900"
                marginBottom={12}
              >
                Página não encontrada
              </Title>
              <Text
                size="body03"
                color="babar700"
                maxWidth={400}
                marginBottom={32}
              >
                Desculpe, mas a página que você está procurando não foi
                encontrada.
              </Text>
              <Button
                to="/"
                title="Voltar para página inicial"
                tag={Link}
                color="sonic500"
                appearance="link"
                leftIcon={<Vector name="arrow-left" color="sonic300" />}
                iconSize={12}
              >
                Voltar para página inicial
              </Button>
            </Block>
          </S.Card>
        </Col>
      </Row>
    </Container>
  </Block>
)

export default NotFoundView
